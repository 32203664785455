<template>
    <div class="grid">  
		<div class="col-12">  
			<div class="card">  
                <Toolbar class="pl-0 pr-0">
                    <template v-slot:start> 
                        <h3>Manage {{module_name}}</h3>  
                    </template>
					<template v-slot:end> 
                        <!-- <router-link v-if="permissions[30]" to="/customers/manage-customer-categories">
                            <Button v-tooltip.top="'Manage Customer '" label="Manage Customer Categories" icon="pi pi-arrow-up-right" class="p-button-raised p-button-md p-button-warning mr-2" />
                        </router-link> -->
                    </template>  
				</Toolbar>  
                <Toolbar class="pl-0 pr-0">
					<template v-slot:start> 
                        <Button v-if="checkPermission(moduleId,'insert')" v-tooltip.top="'Add New'" :label="showBtnLabels ? 'Add New' : ''" @click="loadForm" icon="pi pi-plus" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-primary mr-2" /> 
                        <Button v-if="checkPermission(moduleId,'view')" v-tooltip.top="'Refresh'" :label="showBtnLabels ? 'Refresh' : ''" icon="pi pi-refresh" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-success mr-2"   @click="getRecords" /> 
                        <Button v-if="checkPermission(moduleId,'export')" v-tooltip.top="'Export'" icon="pi pi-external-link" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-warning mr-2" :label="showBtnLabels ? 'Export' : ''" @click="exportCSV($event)" />
                        <Button v-if="checkPermission(moduleId,'view')" v-tooltip.top="'Remove Filters'" type="button" icon="pi pi-filter-slash" :label="showBtnLabels ? 'Remove Filters' : ''" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-info mr-2"  @click="clearFilter()"/> 
                        <Button v-if="checkPermission(moduleId,'delete')" v-tooltip.top="'Delete Selected'" :label="showBtnLabels ? 'Delete Selected' : ''" icon="pi pi-trash" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-danger mr-2"  @click="deleteRecord" :disabled="!selectedRecords || !selectedRecords.length" />
                        <Button v-if="!filterFormDisplay" v-tooltip.top="'Filter Records'" :label="showBtnLabels ? 'Filter Records' : ''" icon="pi pi-filter" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-helper mr-2"  @click="filterFormDisplay= !filterFormDisplay" />
					</template> 
                    <template v-slot:end> 
                        <Button  v-if="checkPermission(moduleId,'view')" v-tooltip.top="'Helping Material'" :label="showBtnLabels ? 'Help' : ''" @click="showHelp = true" icon="pi pi-question" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-info mr-2"  /> 
                    </template> 
				</Toolbar> 
                <Panel class="mt-3 mb-3" v-if="filterFormDisplay">
                    <template #header>
                        <h3>Filters</h3>
                    </template>
                    <div class="col-12 ">
                        <form @submit.prevent="prevent" @keydown="form.onKeydown($event)" enctype="multipart/form-data" class="grid">  
                            <div class="col-3">
                                <label>Order Date</label>
                                <Calendar  selectionMode="range" :manualInput="false" :dateFormat="calendarFormat()" :hourFormat="userData['company_time_format'] ? userData['company_time_format'] : 24"  id="filter_order_date"  v-model="filterForm.order_date" :showTime="false" :showSeconds="false" class="full-width"/> 
                            </div>
                            <div class="col-4 grid"> 
                                <div class="col-6" >
                                    <label>Order Price From</label>
                                    <InputNumber  id="filter_order_price_from" placeholder="From" v-model="filterForm.order_price_from" :showButtons="true" :min="0" class="full-width"/>
                                </div>
                                <div class="col-6">
                                    <label>Order Price To</label>
                                    <InputNumber  id="filter_order_price_to" placeholder="To" v-model="filterForm.order_price_to" :showButtons="true" :min="0" class="full-width"/>
                                </div>  
                            </div>
                            <div class="col-3" > 
                                    <label><span class="pi pi-star-fill important mr-1"></span>Order Category</label>
                                    <Dropdown :disabled="viewableOnly" orderCategoriesLoading :filter="true" :showClear="true"  v-model="filterForm.category_id" :options="orderCategories" optionLabel="name" optionValue="id"  :placeholder="orderCategoriesLoading ? 'Loading...' : 'Select Category'" class="full-width"/>
                                    <HasError class="p-error" :form="filterForm" field="category_id" />
                                </div>  
                            <Divider />
                            <div class="col-12">
                                <Button type="submit" label="Filter Records" :loading="isFiltering" icon="pi pi-check" @click="filterFormDisplay=false, getRecords()" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]"  class="p-button-raised  p-button-info mr-2"/>
                                <Button type="button" label="Reset" icon="pi pi-refresh" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]"  class="p-right p-button-raised p-button-warning mr-2" @click="resetFilterForm()" />
                                <Button type="button" label="Cancel" icon="pi pi-times" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]"  class="p-right p-button-raised p-button-danger mr-2" @click="filterFormDisplay=false, resetFilterForm()"/>
                            </div>
                        </form>
                    </div>
                </Panel>
				<DataTable :reorderableColumns="true" @columnReorder="onColReorder" :resizableColumns="true" columnResizeMode="fit" ref="dt" :value="records" :paginator="true" sortMode="multiple" class="p-datatable-gridlines p-datatable-sm"  :rows="10" dataKey="id" :rowHover="true" 
					v-model:filters="filters" filterDisplay="menu" :loading="loading" :filters="filters" responsiveLayout="scroll"
					:globalFilterFields="['name','company', 'description','added_by','modified_by','date_added','status']" :rowsPerPageOptions="[10,20,50,100]" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" v-model:selection="selectedRecords" selectionMode="multiple"
                    :scrollable="true" scrollHeight="400px">
                    
					<template #header>
                        <div class="flex justify-content-between flex-column sm:flex-row">  
                            <span class="p-input-icon-left mb-2">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Keyword Search" style="width: 100%"/>
                            </span>
                            <MultiSelect :modelValue="selectedColumns" :options="columns" optionLabel="header" @update:modelValue="onToggle"
                                placeholder="Select Columns" style="width: 20em"/>
                        </div>
                    </template>
                    
                    <template #empty> 
                        <div class="table-message"> 
                            <img class="table-message-img" src="layout/images/no-record-found.png" alt="No record found" >
                        </div>
                    </template>
                    <template #loading>
                        Loading data. Please wait.
                    </template> 
                    
                    <Column  selectionMode="multiple" style="max-width:3rem;flex-grow:1; flex-basis:160px"></Column>
                    <Column  v-for="(col, index) of selectedColumns" :field="col.field" :header="col.header" :exportable="col.field == 'action' ? false:true" :sortable="col.field == 'action' ? false:true" :key="col.field + '_' + index" :class="col.field == 'action' ? 'btnAction':''" :style="col.field !== 'action' ? 'min-width:12rem;flex-grow:1; flex-basis:160px;':'max-width:5rem;flex-grow:1; flex-basis:160px;'"> 
                        <template  #body="{data}">
                            <img  v-if="col.field == 'company' && data.logo" :alt="data.company" :src="filesUrl+'companies/'+data.logo" class="tableProfileImg mr-2" />
                            <img  v-if="col.field == 'company' && !data.logo" :alt="data.company" :src="filesUrl+'defaults/default-logo.png'" class="tableProfileImg mr-2" />
                            <span v-if="col.field == 'company'">{{data.company}}</span>
                            <span v-if="col.field == 'order_number'" @click="selectedRecordId=data.id,getClickedRecord('view')">#{{data.order_number}}  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <span v-if="data.total_schedules" class="pi pi-clock" style="float:right"></span></span>   
                            <span @click="showUserProfile(data.user_id)" v-if="col.field == 'user_name'"><strong>{{data.user_name}}</strong><br/><small>{{data.user_email}}</small></span>  
                            
                            <img @click="showUserProfile(data.agent_id)" v-if="col.field == 'agent_name' && data.agent_profile_pic && data.agent_name" alt="" :src="filesUrl+'users/'+data.agent_profile_pic" class="tableProfileImg mr-2" />
                            <img @click="showUserProfile(data.agent_id)"  v-if="col.field == 'agent_name' && !data.agent_profile_pic && data.agent_name" alt="" :src="filesUrl+'defaults/default.png'" class="tableProfileImg mr-2" />
                            <span @click="showUserProfile(data.agent_id)" v-if="col.field == 'agent_name'"><strong>{{data.agent_name}}</strong></span>  
                            
                            <img @click="showUserProfile(data.driver_id)"  v-if="col.field == 'driver_name' && data.driver_profile_pic && data.driver_name" alt="" :src="filesUrl+'users/'+data.driver_profile_pic" class="tableProfileImg mr-2" />
                            <img @click="showUserProfile(data.driver_id)"  v-if="col.field == 'driver_name' && !data.driver_profile_pic && data.driver_name" alt="" :src="filesUrl+'defaults/default.png'" class="tableProfileImg mr-2" />
                            <span @click="showUserProfile(data.driver_id)" v-if="col.field == 'driver_name'"><strong>{{data.driver_name}}</strong><br/><small>{{data.driver_phone}}</small></span>   

                            <img @click="showUserProfile(data.customer_name)"  v-if="col.field == 'customer_name' && data.customer_profile_pic && data.customer_name" alt="" :src="filesUrl+'users/'+data.customer_profile_pic" class="tableProfileImg mr-2" />
                            <img @click="showUserProfile(data.customer_name)"  v-if="col.field == 'customer_name' && !data.customer_profile_pic && data.customer_name" alt="" :src="filesUrl+'defaults/default.png'" class="tableProfileImg mr-2" />
                            <span @click="showUserProfile(data.customer_name)" v-if="col.field == 'customer_name'"><strong>{{data.customer_name}}</strong><br/><small>{{data.customer_email}}</small></span>   
                            
                            <span v-if="col.field == 'category_name'"> {{data.category_name}} </span>   
                            <img  v-if="col.field == 'customer_name' && data.vehicle_image && data.vehicle_name" alt="" :src="filesUrl+'vehicles/'+data.vehicle_image" class="tableProfileImg mr-2" />
                            <img  v-if="col.field == 'vehicle_name' && data.vehicle_image && data.vehicle_name" alt="" :src="filesUrl+'vehicles/'+data.vehicle_image" class="tableProfileImg mr-2" />
                            <span v-if="col.field == 'vehicle_name'">{{data.vehicle_name}}<br/><small>{{data.vehicle_type_name}}</small></span>   
                            <span v-if="col.field == 'passangers'">{{data.passangers}} </span>   
                            <span v-if="col.field == 'price'"><strong>{{userData['company_currency_code'] ? userData['company_currency_code'] : '$'}} {{data.price}}</strong> <span v-if="data.order_price != data.price"><br> <small>Order Price : {{data.order_price}}</small> <br> <small>Price Change : {{data.price_change}}</small></span> </span>   
                            <span v-if="col.field == 'paid_amount'"><strong>{{userData['company_currency_code'] ? userData['company_currency_code'] : '$'}} {{data.paid_amount > 0 ? data.paid_amount : 0 }}</strong>   </span>   
                            <span v-if="col.field == 'balance'">{{userData['company_currency_code'] ? userData['company_currency_code'] : '$'}} {{data.balance}} </span>   
                            
                            <span v-if="col.field == 'start_journey'">
                                <div v-if="!data.start_journey">
                                    <Button type="button" label="Journey Routes" :id="data.id" @click="toggleDataTable" class="p-button-success"/>
                                    <OverlayPanel  ref="op2" appendTo="body" :showCloseIcon="true" id="overlay_panel" style="width: 450px" :breakpoints="{'960px': '75vw', '640px': '100vw'}"> 
                                        <div v-if="routesLoading" class="loader">
                                            <span class="pi pi-spin pi-spinner"></span>
                                        </div>
                                        <div v-else>
                                            <div v-if="Object.keys( orderRoutes ).length" >
                                                <div class="mb-3" v-for="(routes,routeIndex) in orderRoutes" :key="routeIndex">
                                                    <h3>{{routeIndex.charAt(0).toUpperCase() + routeIndex.slice(1)}} Journey</h3>
                                                    <Divider />
                                                    <div v-for="(route,routeKey) in routes" :key="routeKey" class="card mb-1">
                                                        <div class="col-12">
                                                            {{route.address}}
                                                            <span v-if="route.country_name">{{route.country_name}}</span>
                                                            <span v-if="route.state_name">{{route.state_name}}</span>
                                                            <span v-if="route.city_name">{{route.city_name}}</span>
                                                        </div>
                                                        <div class="col-12">
                                                            <span v-if="route.latitude"><strong>Latitude:</strong> {{route.latitude}}</span>
                                                            <span v-if="route.longitude"><strong> | Longitude:</strong> {{route.longitude}}</span>
                                                        </div>
                                                        <div class="col-12">
                                                            <span v-if="route.notes"><strong>Notes:</strong> {{route.notes}}</span> 
                                                        </div>
                                                    </div> 
                                                </div> 
                                            </div>
                                            <div v-else><h4>No Routes Found!</h4></div>
                                        </div>
                                        
                                    </OverlayPanel>
                                </div>
                                <span v-else>N/A</span>
                            </span>  
                            <span v-if="col.field == 'order_start_date'"><span v-if="data.order_start_date">{{formatDateTime(data.order_start_date)}}</span><span v-else>N/A</span></span>  
                            <span v-if="col.field == 'order_end_date'"><span v-if="data.order_end_date">{{formatDateTime(data.order_end_date)}}</span><span v-else>N/A</span></span>   
                            <span v-else-if="col.field == 'description'">{{shortStr(data.description,0,50)}}</span>
                            <span v-else-if="col.field == 'date_added'">{{formatDateTime(data.date_added)}}</span>
                            <span v-else-if="col.field == 'added_by'">
                                <span v-if="data.added_by">{{ data.added_by }}</span>
                                <span v-else>System</span>
                            </span>
                            <span v-else-if="col.field == 'modified_by'">{{ data.modified_by}}</span>
                            <span v-else-if="col.field == 'date_modification'">{{formatDateTime(data.date_modification)}}</span>
                            <span v-else-if="col.field == 'order_status' && data.order_status"> 
                                <Tag class="mr-2" :icon="orderStatuses[data.order_status].icon" :severity="orderStatuses[data.order_status].color" :value="data.order_status"></Tag>
                            </span>
                            <span v-else-if="col.field == 'action'">
                                <div :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}">
                                    <Button icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text" :recordId="data.id" @click="toggleRecordMenu"></Button>
                                    <Menu ref="menu" :popup="true" :model="actions" ></Menu>
                                </div> 
                            </span>
                        </template>
                        <template #filter="{filterModel}">
                            <span v-if="col.field == 'company'"> 
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by company name"/>
                            </span> 
                            <span v-if="col.field == 'user_name'"> 
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by user name"/>
                            </span> 
                            <span v-if="col.field == 'order_number'"> 
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by ID"/>
                            </span> 
                            <span v-if="col.field == 'order_status'"> 
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by order status"/>
                            </span> 
                            <span v-if="col.field == 'category_name'"> 
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by category"/>
                            </span> 
                            <span v-if="col.field == 'price'"> 
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by order price"/>
                            </span> 
                            <span v-if="col.field == 'paid_amount'"> 
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by paid amount"/>
                            </span> 
                            <span v-if="col.field == 'passangers'"> 
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by order passangers"/>
                            </span> 
                            <span v-if="col.field == 'balance'"> 
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by order balance"/>
                            </span> 
                            <span v-if="col.field == 'order_start_date'"> 
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by order start date"/>
                            </span> 
                            <span v-if="col.field == 'order_end_date'"> 
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by order end date"/>
                            </span>  
                            <span v-if="col.field == 'added_by'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                            </span> 
                            <span v-if="col.field == 'modified_by'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                            </span> 
                            <span v-else-if="col.field == 'date_added'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Date Added"/>
                            </span>
                            <span v-else-if="col.field == 'date_modification'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Date Modification"/>
                            </span> 
                            <span v-else-if="col.field == 'status'">
                                <Dropdown v-model="filterModel.value" :options="statuses" placeholder="Any" class="p-column-filter" :showClear="true">
                                    <template #value="slotProps">
                                        <span :class="'customer-badge status-' + slotProps.value" v-if="slotProps.value">{{slotProps.value}}</span>
                                        <span v-else>{{slotProps.placeholder}}</span>
                                    </template>
                                    <template #option="slotProps">
                                        <span :class="'customer-badge status-' + slotProps.option">{{slotProps.option}}</span>
                                    </template>
                                </Dropdown>
                            </span>  
                        </template>   
                    </Column>   
				</DataTable>
			</div>
            
		</div>

        <Sidebar v-model:visible="showStatusChangedForm" :baseZIndex="1000" :autoZIndex="true" :dismissable="false" :showCloseIcon="true" @hide="showStatusChangedForm=false;removeViewFromURL('change-order-status')" position="right" :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'"> 
            <form  @submit.prevent="login" @keydown="orderStatusForm.onKeydown($event)" enctype="multipart/form-data"> 
                <div class="mt-0 mb-0">
                    <div class="col-12 lg:col-12 mb-0">
                        <h3 class="mb-0">Order#{{selectedRecordId}}</h3> 
                        <p>Update order status with reason </p>
                    </div>
                    <Divider class="mt-0" />
                    <div class="grid modal-body">  
                        <div class="col-12 lg:col-12 sm:col-12 md:col-12">
                            <div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                   <h4>Order status is being changed to <span style="color:red">"{{orderStatusForm.status}}"</span></h4>
                                </div>  
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label><h5>Status Change Description</h5></label>
                                    <Textarea placeholder="Description" v-model="orderStatusForm.description" rows="5" cols="30" class="full-width" />
                                    <HasError class="p-error" :form="orderStatusForm" field="description" />
                                </div>  
                            </div>  
                        </div>  
                    </div>
                    <Divider />
                    <div class="col-12 lg:col-12">
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:start>
                                <Button type="submit" label="Change Status" :loading="isStatusSaving" icon="pi pi-check" @click="changeOrderStatus()" class="p-button-raised p-button-lg p-button-success mr-2"/>
                            </template>
                            <template v-slot:end> 
                                <Button type="button" label="Cancel" :loading="isStatusSaving" icon="pi pi-times" class="p-right p-button-raised p-button-lg p-button-danger mr-2" @click="showStatusChangedForm=false;removeViewFromURL('change-order-status')"/>
                            </template> 
                        </Toolbar>   
                    </div>
                </div> 
            </form>
        </Sidebar>  

        <Toast />
        <ConfirmDialog group="dialog" />
        <!-- form loading -->
        <OrdersForm @closeOrderFrom="closeForm" v-if="showForm  && (checkPermission(moduleId,'insert') || checkPermission(moduleId,'update'))" :orderView="orderView" :recordId="selectedRecordId" :showForm="true" /> 
        <ModuleHelp v-on:closeHelp="showHelp =false;removeViewFromURL('view-help')" v-if="showHelp  && checkPermission(moduleId,'view')" :moduleId="moduleId"/>
        <UserProfileView v-on:UserProfileClose="showUserProfileView=false;removeViewFromURL('view-user-profile')"  v-if="showUserProfileView  && (permissions[5].includes('insert') || permissions[5].includes('update'))" :userType="'employee'" :recordId="selectedUserId" :showForm="true" /> 
   </div>
</template>
<script>
 
import UserProfileView from '../../components/AccessManagement/UserProfileView.vue';
import OrdersForm from '../../components/Orders/OrdersForm.vue';
import ModuleHelp from '../../components/Help/ModuleHelp.vue';
import {FilterMatchMode,FilterOperator} from 'primevue/api';  
export default {
    data() {
			return {  
                filterForm: new this.Form({
                    order_date:'',
                    category_id:0,
                    order_price_from: '',
                    order_price_to: '',
                }),
                orderStatusForm: new this.Form({ 
                    id:0,
                    description:'',    
                    status:'',
                }),
                module_name:'Orders', 
                actions: [], 
                moduleId:40,
                showHelp : false, 
                showUserProfileView:false,
                filterFormDisplay:false,
                isFiltering:false, 
                isSaving:false, 
                isStatusSaving:false,
                routesLoading:false,
				records: null, 
				filters: null, 
				loading: true,  
				selectedRecords: null,
				columns: null,
                selectedColumns: null, 
                showForm:false,
                selectedRecordId: 0,  
                selectedUserId:0, 
                saveAndClose:false, 
                showStatusChangedForm:false, 
                orderCategoriesLoading:false,
                newOrderStatus:'',
                orderCategories:[],
                orderStatuses:[],
                orderRoutes:[],  
                orderView : false,
			}
		},  
        components:{
            OrdersForm,
            ModuleHelp,
            UserProfileView, 
        },
        beforeCreate(){

            
            
        },
		created() { 
            if(this.checkPermission(this.moduleId,'update')){
                this.actions.push({
                    label: 'Update',
                    icon: 'pi pi-refresh',
                    command: () => {
                        this.getClickedRecord('update');
                    }
                });
            }
            if(this.checkPermission(this.moduleId,'view')){
                this.actions.push({
                    label: 'View',
                    icon: 'pi pi-eye',
                    command: () => {
                        this.getClickedRecord('view');
                    }
                });
            }
            if(this.checkPermission(this.moduleId,'delete')){
                this.actions.push({
                    label: 'Delete',
                    icon: 'pi pi-times',
                    command: () => {
                        this.getClickedRecord('delete');
                    }
                });
            }  
            if(this.checkPermission(this.moduleId,'update')){
                this.actions.push(
                    {separator:true},
                    {label:'New', icon:'pi pi-eye-slash',command: () => { this.getClickedRecord('New'); }}, 
                    {label:'Confirmed', icon:'pi pi-check',command: () => { this.getClickedRecord('Confirmed'); }},
                    {label:'Delivered', icon:'pi pi-check-circle',command: () => { this.getClickedRecord('Delivered'); }}, 
                    {label:'Canceled', icon:'pi pi-times',command: () => { this.getClickedRecord('Canceled'); }}
                );
            } 
			this.initFilters();
            this.columns = [
                {field: 'action', header: 'Action'},
                {field: 'order_number', header: 'Order ID#'},  
                {field: 'company', header: 'Company'},  
                {field: 'order_category', header: 'Category'},     
                {field: 'customer_name', header: 'Customer Info'},     
                {field: 'driver_name', header: 'Driver Info'},     
                {field: 'agent_name', header: 'Agent Info'},     
                {field: 'vehicle_name', header: 'Vehicle Info'},   
                {field: 'passangers', header: 'Order Passangers'},     
                {field: 'price', header: 'Order Price'},     
                {field: 'paid_amount', header: 'Paid Amount'},    
                {field: 'balance', header: 'Customer Balance'},     
                {field: 'start_journey', header: 'Path Info'},     
                {field: 'order_start_date', header: 'Start Date'},     
                {field: 'order_end_date', header: 'End Date'},     
                {field: 'order_status', header: 'Order Status'},   
                {field: 'added_by', header: 'Created By'},
                {field: 'date_added', header: 'Date Added'},
                {field: 'modified_by', header: 'Last Modified By'},
                {field: 'date_modification', header: 'Date Modification'}, 
            ];
            this.selectedColumns = this.columns;
		},
        computed: { 
        },
		mounted() {  
            if(this.$route.query.view){
                this.urlParams = JSON.parse(this.$CryptoJS.AES.decrypt(this.$route.query.view, this.encryptKey).toString(this.$CryptoJS.enc.Utf8));
                if(Object.keys(this.urlParams).length){
                    this.urlParams.forEach( (parameter) => {
                        if(parameter.view == 'add-order'){
                            this.selectedRecordId = parameter.id;
                            this.showForm = true;
                        }
                        if(parameter.view == 'view-order'){
                            this.selectedRecordId = parameter.id;
                            this.showForm = true;
                            this.orderView = true;
                        }
                        else if(parameter.view == 'view-order-user-profile'){ 
                            this.selectedUserId = parameter.id;
                            this.showProfileView = true;
                        }
                        else if(parameter.view == 'view-user-profile'){ 
                            this.selectedUserId = parameter.id;
                            this.showUserProfileView = true;
                        }
                        else if(parameter.view == 'view-help'){ 
                            this.selectedRecordId = parameter.id;
                            this.showHelp = true;
                        }
                        else if(parameter.view == 'change-order-status'){ 
                            this.selectedRecordId = parameter.id;
                            this.orderStatusForm.reset();
                            this.showStatusChangedForm = true; 
                            this.orderStatusForm.status = parameter.order_status;
                        }
                    })
                }
            }
            

            //THIS CODE IS TO TABLE COLUMNS SETTINGS
            this.axios.post('getAllModuleColumnSettings', { 'module_id': this.moduleId })
            .then((response) => {
                if(response.data){
                    if(Object.keys(response.data).length){
                        this.columns =[]; 
                        this.selectedColumns = [];
                        response.data.forEach( (column) => { 
                            this.columns.push({'field':column.column_field, 'header': column.column_head});
                            if(column.display){
                                this.selectedColumns.push({'field':column.column_field, 'header': column.column_head});
                            } 
                        }); 
                    }
                }
            })
            .catch(() => {
            })
            .finally(() => {   
            })
            //END
             
            this.getRecords();  
            this.getOrderCategories();
            this.orderStatuses['New'] = {label:'New', icon:'pi pi-eye-slash', color:'warning'};  
            this.orderStatuses['Confirmed'] = {label:'Confirmed', icon:'pi pi-check', color:'info'};
            this.orderStatuses['Delivered'] = {label:'Paid', icon:'pi pi-check-circle', color:'success'};
            this.orderStatuses['Canceled'] =  {label:'Canceled', icon:'pi pi-times', color:'danger'}; 
		},
		methods: {   
            resetFilterForm(){
                this.filterForm.reset();
            },
            onColReorder(event){ 
                this.columns = this.tableColumnOrderChange(event,this.columns);  
                this.saveTableColumnOrderChange(this.moduleId,this.columns,this.selectedColumns);
            },
            onToggle(value) {  
                //this.selectedColumns = this.columns.filter(col => value.includes(col)); 
                this.selectedColumns = []; 
                let newColumns = []; 
                this.columns.filter((col) => {
                    if(value.find(x => x.field === col.field)){
                        newColumns.push(col);
                        return col;
                    } 
                }); 
                this.selectedColumns = newColumns; 
                this.saveTableColumnOrderChange(this.moduleId,this.columns,this.selectedColumns);
            },
            showUserProfile(userId){
                this.selectedUserId = userId;
                this.showUserProfileView = true;
                this.addViewInURL({view: 'view-user-profile', id: this.selectedUserId});
            },
             
            toggleDataTable(event) {
				this.$refs.op2.toggle(event); 
                this.getOrderRoutes(event.currentTarget.id);
			},
            async getOrderRoutes(order_id){ 
                var Vthis = this;
                this.routesLoading = true; 
                this.orderRoutes = [];
                await this.axios.post('getOrderRoutesInfo',{order_id : order_id})
                .then( (response) => {  
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){
                        this.orderRoutes = response.data;   
                    }
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => { 
                    Vthis.routesLoading = false;  
                })
            },
            async changeOrderStatus(){    
                var Vthis = this; 
                Vthis.orderStatusForm.id = Vthis.selectedRecordId;
                this.isStatusSaving = true;
                await this.orderStatusForm
                .post(Vthis.$baseurl+'api/changeOrderStatus', Vthis.orderStatusForm)
                .then((response) => {  
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){
                        // this.form.reset();    
                        Vthis.showMessage('Saved successfully','success'); 
                        this.showStatusChangedForm = false;
                    }
                })
                .catch((error) => {   
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.isStatusSaving = false; 
                }) 
            },    
            getClickedRecord(action){
                if(action == 'delete'){ 
                    this.deleteRecord(); 
                }
                else if(action == 'view'){
                    this.addViewInURL({view: 'view-order', id: this.selectedRecordId});
                    this.showForm = true; 
                    this.orderView = true; 
                }  
                else if(action == 'update'){ 
                    this.addViewInURL({view: 'add-order', id: this.selectedRecordId});
                    this.showForm = true; 
                    this.orderView = false; 
                }
                else if(action == 'New'){
                    this.orderStatusForm.reset();
                    this.showStatusChangedForm = true; 
                    this.orderStatusForm.status = 'New';
                    this.addViewInURL({view: 'change-order-status', id: this.selectedUserId, order_status:'New'});
                }
                else if(action == 'Confirmed'){
                    this.orderStatusForm.reset();
                    this.showStatusChangedForm = true; 
                    this.orderStatusForm.status = 'Confirmed';
                    this.addViewInURL({view: 'change-order-status', id: this.selectedUserId, order_status:'Confirmed'});
                }
                else if(action == 'Delivered'){
                    this.orderStatusForm.reset();
                    this.showStatusChangedForm = true; 
                    this.orderStatusForm.status = 'Delivered';
                    this.addViewInURL({view: 'change-order-status', id: this.selectedUserId, order_status:'Delivered'});
                }
                else if(action == 'Canceled'){
                    this.orderStatusForm.reset();
                    this.showStatusChangedForm = true; 
                    this.orderStatusForm.status = 'Canceled';
                    this.addViewInURL({view: 'change-order-status', id: this.selectedUserId, order_status:'Canceled'});
                }
            },
            toggleRecordMenu(event) {
                this.selectedRecordId = event.currentTarget.getAttribute('recordId');  
				this.$refs.menu.toggle(event);
			},
            loadForm(){  
                this.selectedRecordId = 0;
                this.addViewInURL({view: 'add-order', id: this.selectedRecordId});
                this.showForm = true; 
            },
            closeForm(){  
                if(this.orderView){ 
                    this.orderView = false; 
                    this.showForm = false; 
                    this.removeViewFromURL('view-order'); 
                }
                else{
                    this.removeViewFromURL('add-order');
                    this.showForm = false; 
                }
                 
                if(!this.selectedRecordId){
                    this.getRecords();
                }  
            },
            async getOrderCategories(){   
                 var Vthis = this;
                 Vthis.orderCategoriesLoading = true;
                 await this.axios.post('getAllOrderCategories',{ dropdown:true})
                 .then( (response) => {
                     let responseStatus = this.printResponseResult(response,false); 
                     if(responseStatus == 200){ 
                         if(response.data){ 
                             this.orderCategories = response.data; 
                         }   
                     }
                 })
                 .catch((error) => {    
                     this.printResponseResult(error);
                 })
                 .finally(() => { 
                     Vthis.orderCategoriesLoading = false;
                 })  
                
             }, 
            getRecords(){ 
                var Vthis = this;
                this.loading = true;
                var status = this.$route.query.status; 
                this.filterForm.order_status = status;
                this.axios.post('getAllOrders',{filters : this.filterForm})
                .then( (response) => {  
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){
                        this.records = response.data;  
                        this.loading = false; 
                    }
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => { 
                })  
            }, 
            viewRecord(){ 
                var Vthis = this;
                this.loading = true;
                this.axios.post('getAllOrders',{id:this.selectedRecordId})
                .then( (response) => {  
                    this.printResponseResult(response,false);  
                })
                .catch((error) => {   
                    this.printResponseResult(error);
                })
                .finally(() => { 
                })  
            },
            deleteRecord(){ 
                var Vthis = this;
                this.$confirm.require({
                    group: 'dialog',
                    header: 'Confirmation',
                    message: 'Are you sure you want to delete?',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => { 
                        Vthis.loading = true;  
                        let DeleteAbleRecords = [];
                         
                        if(Vthis.selectedRecords){
                            Vthis.selectedRecordId = '';
                            Vthis.selectedRecords.forEach((data) => {
                                DeleteAbleRecords.push(data.id);
                            })
                        } 
                        else{
                            DeleteAbleRecords.push(Vthis.selectedRecordId);
                        } 
                         
                        // let filterdRecords = Vthis.records.filter(val => Vthis.selectedRecords.includes(val)); 
                        // filterdRecords.forEach((data) => {
                        //     DeleteAbleRecords.push(data.id);
                        // })  

                        Vthis.axios.post('deleteOrder',{id:DeleteAbleRecords})
                        .then( (response) => {   
                            let responseStatus = this.printResponseResult(response,false); 
                            if(responseStatus == 200){
                                Vthis.getRecords();
                                Vthis.showMessage('Record deleted successfully','success'); 
                            }
                        })
                        .catch((error) => {   
                            this.printResponseResult(error);
                        })
                        .finally(() => { 
                            Vthis.loading = false;
                        })
                    },
                    reject: () => {
                        // this.loading = false;
                    }
                }); 
            },
            exportCSV() {
                this.$refs.dt.exportCSV();
            },
            
            
            initFilters() {
				this.filters = {
					'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'order_number': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'user_name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'agent_name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'category_name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'driver_name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'vehicle_name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
                    'passangers': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'price': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'paid_amount': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'balance': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'order_status': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'order_start_date': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'order_end_date': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'company': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'added_by': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'modified_by': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
                    'date_added': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]}, 
					'date_modification': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]}, 
					'status': {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]}, 
				}
			},
			clearFilter() {
				this.initFilters();
                this.filterForm.reset();
                this.getRecords();
			}, 
            isRTL() {
				return this.$appState.RTL;
			}
		},
        watch : { 
        }
}
</script>
 
